import React from "react"

import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import AboutSection from "../ProProfileShared/AboutSection"
import BottomCTA from "../ProProfileShared/BottomCTA"
import EndorsementsSection from "../ProProfileShared/EndorsementsSection"
import Footer from "../ProProfileShared/Footer"
import GallerySection from "../ProProfileShared/GallerySection"
// import MobileHeroSection from "../ProProfileShared/HeroSection"
import Map from "../ProProfileShared/Map"
import Navigation from "../ProProfileShared/Navigation"
import Quote from "../ProProfileShared/Quote"
import ReviewsSection from "../ProProfileShared/ReviewsSectionVariationOne"
import ServicesSection from "../ProProfileShared/ServicesSection"
import TopReviewsSection from "../ProProfileShared/TopReviewsSection"

import HeroSection from "./HeroSection"
import ValuesStatementSection from "./ValueStatementSection"

const ProProfileThemeThree = ({ practice, highlight, styles, viewingInBuilder }) => {
  const theme = practice.theme
  const secondaryColor = theme.colorSecondaryDark
  const secondaryTextColor = theme.colorTextLight
  const primaryColor = theme.colorPrimaryDark
  const primaryTextColor = theme.colorTextLight

  return (
    <GraphQLProvider>
      <ToastProvider>
        <main style={styles} className="md:overflow-x-hidden">
          <div>
            <HeroSection
              practice={practice}
              theme={theme}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
              Navigation={() => (
                <Navigation
                  practice={practice}
                  theme={theme}
                  color={theme.colorTextDark}
                  viewingInBuilder={viewingInBuilder}
                />
              )}
            />
          </div>
          {/* <div className="hidden md:block">
            <MobileHeroSection
              practice={practice}
              theme={theme}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
              Navigation={() => <Navigation practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />}
            />
          </div> */}
          <TopReviewsSection practice={practice} theme={theme} />
          <ServicesSection
            practice={practice}
            theme={theme}
            includeImage={true}
            buttonLeft={true}
            viewingInBuilder={viewingInBuilder}
            titleClassName="mt-10 min_md:mt-32 text-left md:text-center"
            className="pt-12 md:pb-20 md:pt-0"
            imageClassName="absolute bottom-0 left-0 h-auto"
            mobileImageClassName="px-0 mb-0"
            animateTitle={true}
          />
          <ValuesStatementSection
            practice={practice}
            theme={theme}
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
          />
          <AboutSection
            practice={practice}
            theme={theme}
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
            credentialsClassName="p-0"
          />
          <ReviewsSection
            practice={practice}
            theme={theme}
            backgroundColor={secondaryColor}
            color={secondaryTextColor}
            starsClassName="justify-start md:justify-center"
          />
          <EndorsementsSection
            practice={practice}
            theme={theme}
            backgroundColor={secondaryColor}
            color={secondaryTextColor}
          />
          <GallerySection
            practice={practice}
            theme={theme}
            style={{ backgroundColor: theme.colorBgLight }}
            highlight={highlight === "Gallery photos"}
          />
          <Quote
            theme={theme}
            text={practice.quote}
            author={practice.quoteAuthor}
            id="quote"
            highlight={highlight === "quote"}
            backgroundColor={primaryColor}
            color={primaryTextColor}
          />
          <BottomCTA practice={practice} theme={theme} highlight={highlight} viewingInBuilder={viewingInBuilder} />
          <Map practice={practice} />
          <Footer practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />
        </main>
      </ToastProvider>
    </GraphQLProvider>
  )
}

export default ProProfileThemeThree
